import { AuthOptions } from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';
import GoogleProvider from 'next-auth/providers/google';
import { extend } from 'lodash';

async function getUserInfo(token: string) {
  const rsp = await fetch(`${process.env.SERVER_URL}/users/api/get_user_info`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  if (!rsp.ok) {
    return null;
  }
  return await rsp.json();
}

export const NEXT_AUTH_OPTIONS: AuthOptions = {
  providers: [
    CredentialsProvider({
      name: 'Campfire',
      credentials: {
        username: {
          label: 'Email',
          type: 'text',
        },
        password: {
          label: 'Password',
          type: 'password',
        },
      },
      async authorize(credentials, req) {
        try {
          const res = await fetch(`${process.env.SERVER_URL}/auth-token/`, {
            method: 'POST',
            body: JSON.stringify(credentials),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          if (res.status === 403) {
            throw new Error('PasswordNotAllowed');
          }
          if (res.status === 429) {
            throw new Error('TooManyRequests');
          }
          if (res.ok) {
            const token = await res.json();
            const user = await getUserInfo(token.token);
            return { ...token, ...user };
          } else {
            return null;
          }
        } catch (e: any) {
          if (['PasswordNotAllowed', 'TooManyRequests'].includes(e.message)) {
            throw e;
          }
          return null;
        }
      },
    }),
    GoogleProvider({
      clientId: process.env.GOOGLE_CLIENT_ID || '',
      clientSecret: process.env.GOOGLE_CLIENT_SECRET || '',
    }),
  ],
  callbacks: {
    async signIn({ user, account }) {
      if (account?.provider === 'google' && user?.email) {
        const tokenRsp = await fetch(
          `${process.env.SERVER_URL}/auth-token-from-email/`,
          {
            method: 'POST',
            body: JSON.stringify({
              email: user?.email,
              secret: process.env.API_SECRET,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        if (!tokenRsp.ok) {
          return false;
        }
        const token = await tokenRsp.json();
        extend(user, token, await getUserInfo(token.token));
      }
      return true;
    },
    async jwt({ token, user }) {
      return { ...token, ...user };
    },
    async session({ session, token }) {
      // @ts-ignore
      session.user = token;

      return session;
    },
  },
  session: {
    maxAge: 10 * 60 * 60,
  },
  pages: {
    signIn: '/auth/sign-in',
    error: '/auth/sign-in',
  },
};
