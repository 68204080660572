'use client';

import './globals.scss';
import { Toaster } from '&/components/ui/toaster';
import { LicenseManager } from 'ag-grid-enterprise';
import { SWRConfig } from 'swr';
import axios from '&/lib/axios';

LicenseManager.setLicenseKey(
  'Using_this_AG_Grid_Enterprise_key_( AG-047103 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Campfire )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Campfire )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Campfire )_need_to_be_licensed___( Campfire )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 28 August 2024 )____[v2]_MTcyNDc5OTYwMDAwMA==32b2e3987d43f930c1f24e522da6c03c',
);

export default function V2Layout({ children }: { children: React.ReactNode }) {
  function fetcher(url: string) {
    return axios.get(url).then((res) => res.data);
  }

  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      <div className="h-full font-sans">
        <Toaster />
        <div>{children}</div>
      </div>
    </SWRConfig>
  );
}
