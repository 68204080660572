import axios from 'axios';
import { getServerSession } from 'next-auth';
import { getSession, signOut } from 'next-auth/react';
import { NEXT_AUTH_OPTIONS } from './utils';

export const axiosInstance = axios.create({
  baseURL: process.env.SERVER_URL,
});

axiosInstance.interceptors.request.use(async (config) => {
  const session = await (async () => {
    if (typeof window === 'undefined') {
      return await getServerSession(NEXT_AUTH_OPTIONS);
    }
    return await getSession();
  })();

  if (session?.user?.token) {
    config.headers.Authorization = `Token ${session?.user?.token}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (typeof window !== 'undefined') {
      if (error.response) {
        const { status } = error.response;
        if (status == 401) {
          signOut({
            callbackUrl: '/auth/sign-in',
          });
          return null;
        }
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
